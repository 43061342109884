import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ThanksBg from '../components/ThanksBg';
import Fade from 'react-reveal/Fade';

const ThanksPage = () => (
	<Layout>
		<SEO title="Thank You" />
		<div className="w-100 vh-100 db bg-black relative overflow-hidden">
			<ThanksBg />
			<Fade>
				<div className="w-100 h-100 absolute top-0 left-0 flex items-center">
					<div className="mw8 white center tc">
						<div className="ml4 mr4">
							<div className="f2 f1-l g-medium lh-copy mb3">Thank You</div>
							<div className="f5 f-intro-l measure center">
								<p>Your enquiry has been successfully submitted.</p>
								<p>We will get back to you within 1-2 working days.</p>
								<Link
									to="/"
									className="link yellow button g-medium ttu f5 tracked flex items-center center mt4 justify-center mt4"
								>
									Go Back
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Fade>
		</div>
	</Layout>
);

export default ThanksPage;
